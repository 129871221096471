/* ###### Alert ###### */

.alert {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
  }
  
  .alert-icon {
	padding-left: 3rem;
  
	> i {
	  color: inherit !important;
	  font-size: 1rem;
	  position: absolute;
	  top: 1rem;
	  left: 1rem;
	}
  }
  
  .alert-avatar {
	padding-left: 3.75rem;
  
	.avatar {
	  position: absolute;
	  top: .5rem;
	  left: .75rem;
	}
  }
  
  .close {
	font-size: 1rem;
	line-height: 1.5;
	transition: .3s color;
  }
  
  .alert {
	font-size: 0.9375rem;
  
	&:first-child {
	  margin-bottom: 1rem;
	}
  
	&:last-child {
	  margin-bottom: 0;
	}
  }
  
  .alert-heading {
	color: inherit;
  }
  
  .alert-link {
	font-weight: 500;
  
	&:hover, a:hover {
	  text-decoration: underline;
	}
  }
  
  .alert-dismissible {
	padding-right: 3.90625rem;
  }
  
  hr.message-inner-separator {
	clear: both;
	margin-top: 10px;
	margin-bottom: 13px;
	border: 0;
	height: 1px;
	background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
	background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
	background-image: -ms-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
	background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
  }
  
  .alert-dismissible .close {
	position: absolute;
	top: 0;
	right: 0;
	padding: 0.75rem 1.25rem;
	color: inherit;
  }
  
  .alert-success {
	color: #e2fff3;
	background-color: $success;
	border-color: $success;
  
	hr {
	  border-top-color: #c5e7a4;
	}
  
	.alert-link {
	  color: #172e00;
	}
  }
  
  .alert-info {
	color: #eaf7fb;
	background-color: $info;
	border-color: $info;
  
	hr {
	  border-top-color: #b3dcf9;
	}
  
	.alert-link {
	  color: #193c56;
	}
  }
  
  .alert-warning {
	color: $white;
	background-color: #e5a623;
	border-color: #e5a623;
  
	hr {
	  border-top-color: #fae8a4;
	}
  
	.alert-link {
	  color: #4d3f05;
	}
  }
  
  .alert-danger {
	color: #fbe4e8;
	background-color: #ed314c;
	border-color: #ed314c;
  
	hr {
	  border-top-color: #ecacab;
	}
  
	.alert-link {
	  color: #3f0a09;
	}
  }
  
  .alert-light {
	color: #818182;
	background-color: #fefefe;
	border-color: #fdfdfe;
  
	hr {
	  border-top-color: #ececf6;
	}
  
	.alert-link {
	  color: #686868;
	}
  }
  
  .alert-dark {
	color: #1b1e21;
	background-color: #d6d8d9;
	border-color: #c6c8ca;
  
	hr {
	  border-top-color: #b9bbbe;
	}
  
	.alert-link {
	  color: #040505;
	}
  }
  
  @-webkit-keyframes progress-bar-stripes {
	from {
	  background-position: 1rem 0;
	}
  
	to {
	  background-position: 0 0;
	}
  }
  
  @keyframes progress-bar-stripes {
	from {
	  background-position: 1rem 0;
	}
  
	to {
	  background-position: 0 0;
	}
  }
  
  /* ###### Alert ###### */
/* ###### Navs ###### */

.nav-link, .nav-item {
	min-width: 2rem;
	transition: .3s color;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
	display: -ms-flexbox;
	display: flex ;
	-ms-flex-align: center;
	align-items: center;
  }
  
  .navbar-nav {
	flex-direction: initial !important;
  }
  
  .nav-item1 {
	padding: 10px;
	min-width: 2rem;
	transition: .3s color;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
  }
  
  .nav1 {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	background: rgba(236, 238, 249, 0.9);
  }
  
  .nav-item1 {
	&:hover:not(.disabled), &.active {
	  color: $white;
	}
  
	.nav-link {
	  &.disabled {
		opacity: 6;
		cursor: default;
		pointer-events: none;
	  }
  
	  &.disable {
		opacity: 3;
	  }
	}
  }
  
  .nav-tabs {
	border-bottom: 1px solid $border;
  
	.nav-item1 {
	  margin-bottom: -1px;
  
	  &.nav-link {
		border: 1px solid transparent;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
	  }
  
	  .nav-link {
		border: 0;
		color: inherit;
		margin-bottom: -1px;
		color: #90a1bb;
		transition: .3s border-color;
		font-weight: 400;
  
		&:hover:not(.disabled), &.active {
		  color: $white;
		}
  
		&.disabled {
		  opacity: .6;
		  cursor: default;
		  pointer-events: none;
		}
	  }
  
	  margin-bottom: 0;
	  position: relative;
  
	  i {
		margin-right: .25rem;
		line-height: 1;
		font-size: 0rem;
		width: 0.875rem;
		vertical-align: baseline;
		display: inline-block;
		margin-right: 10px;
	  }
  
	  &:hover .nav-submenu {
		display: block;
	  }
	}
  
	.nav-submenu {
	  display: none;
	  position: absolute;
	  background: $black;
	  border: 1px solid $border;
	  border-top: none;
	  z-index: 10;
	  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
	  min-width: 10rem;
	  border-radius: 0 0 3px 3px;
  
	  .nav-item1 {
		display: block;
		padding: .5rem 1rem;
		color: #9aa0ac;
		margin: 0 !important;
		cursor: pointer;
		transition: .3s background;
  
		&.active {
		  color: #467fcf;
		}
  
		&:hover {
		  color: #6e7687;
		  text-decoration: none;
		  background: rgba(0, 0, 0, 0.024);
		}
	  }
	}
  }
  
  .nav-unread {
	position: absolute;
	top: 0.4rem;
	background: #ecd938;
	width: .5rem;
	height: .5rem;
	border-radius: 50%;
  
	&.badge {
	  position: absolute;
	  top: 0;
	  text-align: center;
	  right: 0;
	  width: 1.2rem;
	  height: 1.2rem;
	  border-radius: 50%;
	}
  }
  
  .nav-link.icon {
	display: block;
	text-align: center;
	font-size: 20px;
	position: relative;
	color: $white !important;
  
	&:hover {
	  color: $white !important;
	}
  }
  
  .nav-tabs {
	.nav-item i {
	  margin-right: .25rem;
	  line-height: 1;
	  font-size: 0rem;
	  width: 0.875rem;
	  vertical-align: baseline;
	  display: inline-block;
	  margin-right: 10px;
	}
  
	.nav-submenu {
	  display: none;
	  position: absolute;
	  background: $white;
	  border: 1px solid $border;
	  border-top: none;
	  z-index: 10;
	  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
	  min-width: 10rem;
	  border-radius: 0 0 3px 3px;
  
	  .nav-item {
		display: block;
		padding: .5rem 1rem;
		color: #9aa0ac;
		margin: 0 !important;
		cursor: pointer;
		transition: .3s background;
  
		&.active {
		  color: #467fcf;
		}
  
		&:hover {
		  color: #6e7687;
		  text-decoration: none;
		  background: rgba(0, 0, 0, 0.024);
		}
	  }
	}
  
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: $black;
	margin: 0 -.75rem;
  
	.nav-link {
	  border: 0;
	  color: inherit;
	  color: #90a1bb;
	  transition: .3s border-color;
	  font-weight: 500;
	  padding: 1rem 1.80rem;
	  font-size: 13px;
	  text-align: center;
  
	  &:hover:not(.disabled), &.active {
		color: $white;
	  }
  
	  &.disabled {
		opacity: .4;
		cursor: default;
		pointer-events: none;
	  }
	}
  
	.nav-item {
	  margin-bottom: 0;
	  position: relative;
  
	  &:hover .nav-submenu {
		display: block;
	  }
	}
  }
  
  .nav-link:hover .dropdown-menu, .nav-item:hover .dropdown-menu, .nav-link:hover .dropdown-menu.show {
	display: block;
  }
  
  /* ###### Navs ###### */
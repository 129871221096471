/* ###### Panels ###### */

.panel1 {
	border-width: 0 0 1px 0;
	border-style: solid;
	border-color: $white;
	background: none;
	box-shadow: none;
  
	&:last-child {
	  border-bottom: none;
	}
  }
  
  .panel-group1 {
	> .panel1:first-child .panel-heading1 {
	  border-radius: 4px 4px 0 0;
	}
  
	.panel1 {
	  border-radius: 0;
	}
  }
  
  .panel-body1 {
	padding: 10px;
  }
  
  .panel-title1 {
	font-size: 14px;
	margin-bottom: 0;
  }
  
  .panel-group1 .panel1 + .panel1 {
	margin-top: 0;
  }
  
  .panel-heading1 {
	background-color: #5797fc;
	border-radius: 0;
	border: none;
	color: $white;
	padding: 0;
  }
  
  .panel-group1 .panel-body {
	border: 1px solid $border;
  }
  
  .panel-title1 a {
	display: block;
	color: $white;
	padding: 10px;
	position: relative;
	font-size: 16px;
	font-weight: 400;
  }
  
  .panel-body1 {
	background: $white;
  }
  
  .panel1:last-child {
	.panel-body1 {
	  border-radius: 0 0 4px 4px;
	}
  
	.panel-heading1 {
	  border-radius: 0 0 4px 4px;
	  transition: border-radius 0.3s linear 0.2s;
  
	  &.active {
		border-radius: 0;
		transition: border-radius linear 0s;
	  }
	}
  }
  
  .panel-group .panel {
	border-radius: 0;
	box-shadow: none;
	border-color: $border;
  }
  
  .panel-default > .panel-heading {
	padding: 0;
	border-radius: 0;
	background-color: rgba(236, 238, 249, 0.9);
	border-color: $border;
  }
  
  .panel-title {
	font-size: 14px;
	margin-bottom: 0;
  
	> a {
	  display: block;
	  padding: 15px;
	  text-decoration: none;
	}
  }
  
  .panel-default > .panel-heading + .panel-collapse > .panel-body {
	border: 1px solid $border;
  }
  
  /* ###### Panels ###### */
$background: #eceef9;
$color:#536d96;

/*Color variables*/
$primary:#765be6; 
$secondary:#e44374;
$pink:#ec82ef;
$teal:#2bcbba;
$purple:#7F53AC;
$success:#2eb67d;
$warning:#f5a623;
$danger:#f5334f;
$info:#26c2f7;
$orange:#fc7303;
$dark:#343a40;
$indigo:#6574cd;
$white:#fff;
$black:#000;
$gray:#868e96;

/*border variables*/
$border:#e0e8f5;

/*shadow variables*/
$shadow:0 0 0 1px rgba(61,119,180,.12), 0 8px 16px 0 rgba(91,139,199,.24);

$dark-theme:#1d1f32;



	



/*------------------------------------------------------------------
[Master Stylesheet]

Project        :   Dashmint – Bootstrap Responsive Flat Admin Dashboard HTML5 Template
Version        :   V.1
Create Date    :   20/03/20
Copyright      :   Spruko Technologies Private Limited 
Author         :   SprukoSoft
Author URL     :   https://themeforest.net/user/sprukosoft
Support		   :   support@spruko.com
License        :   Licensed under ThemeForest License

-------------------------------------------------------------------*/

/* ------ Table Of Contents
	* avatars
	* calender
	* custom-bootstrap
	* custom-control
	* custom-style
	* date-picker
	* email-services
	* highlight
	* jumbotron
	* list
	* media
	* navs
	* panels
	* pricing-tables
	* range-slider
	* select2
	* spinner
	* tags
	* timeline
	* closed-sidemenu
	* footer
	* header
	* horizontal-menu
	* icon-sidemenu
	* right-sidebar
	* sidemenu
	* sidemenu-responsive-tabs
	* accordions
	* alerts
	* badges
	* breadcrumbs
	* buttons
	* cards
	* carousel
	* dropdown
	* forms
	* grid
	* input-group
	* modals
	* navigation
	* pagination
	* popover
	* progress
	* tables
	* tooltip
	* background
	* borders
	* height
	* margin
	* padding
	* typography
	* width
	
*/

@import "custom/fonts/fonts";


/* ########## CUSTOM ########## */
@import "variables";


@import "custom/avatars";
@import "custom/calender";
@import "custom/custom-bootstrap";
@import "custom/custom-control";
@import "custom/custom-style";
@import "custom/date-picker";
@import "custom/email-services";
@import "custom/highlight";
@import "custom/jumbotron";
@import "custom/list";
@import "custom/media";
@import "custom/navs";
@import "custom/panels";
@import "custom/pricing-tables";
@import "custom/range-slider";
@import "custom/select2";
@import "custom/spinner";
@import "custom/tags";
@import "custom/timeline";

/* ########## LAYOUTS ########## */
@import "layouts/footer";
@import "layouts/header";
@import "layouts/horizontal-menu";
@import "layouts/right-sidebar";


/* ############### TEMPLATE STYLES ############### */
@import "template/accordions";
@import "template/alerts";
@import "template/badges";
@import "template/breadcrumbs";
@import "template/buttons";
@import "template/cards";
@import "template/carousel";
@import "template/dropdown";
@import "template/forms";
@import "template/grid";
@import "template/input-group";
@import "template/modals";
@import "template/navigation";
@import "template/pagination";
@import "template/popover";
@import "template/progress";
@import "template/tables";
@import "template/tooltip";

/* ############### UTILITIES/HELPER CLASSES ############### */
@import "util/background";
@import "util/borders";
@import "util/height";
@import "util/margin";
@import "util/padding";
@import "util/typography";
@import "util/width";


/* ###### Header ###### */
.header .form-inline{
	margin-left: 10rem;
}
.header.header-1 .form-inline{
	margin-left: 0rem;
}
.header.header-2 .form-inline{
	margin-left: 0rem;
}
.header.header-style .form-inline{
	margin-left: 0rem;
}
.form-inline .form-check {
	width: 100%;
}
.header {
	padding-top: 10px;
	padding-bottom: 10px;
	background:$white;
	border-bottom: 1px solid transparent;
	z-index:9;
}
.top-header{
	position: fixed;
	left: 0;
	right: 0;
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
@media print {
	.header {
		display: none;
	}
}

@media (max-width: 992px) and (min-width: 768px){
	.header .form-inline .form-control {
	    width: 200px !important;
	}
}

.header .form-inline .form-control {
    border: 1px solid rgba(255,255,255,0.1);
    padding-left: 20px;
    width: 600px;
    padding-right: 40px;
    background: $white;
    color: $color !important;
    border-radius: 4px;
}
.header .form-inline .form-control:focus {
	border: 1px solid rgba(255,255,255,0.1);
	color: $color;
}
.header .form-inline .form-control:hover {
	border: 1px solid rgba(255,255,255,0.1);
	color: $color;
}
.header .form-inline .form-control::-webkit-input-placeholder {
	color:#c4ccdc;
	opacity:1;
}
.header .form-inline .form-control::-moz-placeholder {
	color:#c4ccdc;
	opacity:1;
}
.header .form-inline .form-control:-ms-input-placeholder {
	color:#c4ccdc;
	opacity:1;
}
.header .form-inline .form-control::-ms-input-placeholder {
	color:#c4ccdc;
	opacity:1;
}
.header .mega-menu {
	width: 350px;
}
.header .nav-tabs {
    margin: 0;
}
.header-right-icons a{
	display:block;
	padding-top:9px;
}
.header-right-icons .dropdown-menu a{
	padding-top: 0.5rem;
}
.header-brand {
	color: inherit;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.6rem;
}
.app-header .header-brand {
	color: inherit;
	margin-right: 0;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
	min-width: 200px;
    text-align: center;
}
.app-header .header-brand .header-brand-img {
	margin-right:0;
}
.app-header1 .header-brand {
	color: inherit;
	margin-right: 0;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
	min-width: 215px;
    text-align: center;
}
.app-header1 .header-brand .header-brand-img {
	margin-right:0;
}
.header-brand:hover {
	color: inherit;
	text-decoration: none;
}
.header-brand-img {
	height: 2.5rem;
	line-height: 2rem;
	vertical-align: middle;
	margin-right: .5rem;
	width: auto;
}
.header-avatar {
	width: 2rem;
	height: 2rem;
	display: inline-block;
	vertical-align: bottom;
	border-radius: 50%;
}
.header-btn {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	line-height: 2rem;
	text-align: center;
	font-size: 1rem;
}
.header .nav-link .badge {
	position: absolute;
    top: 3px;
    right: 6px;
    width: 1rem;
    height: 1rem;
    font-size: 11px;
    text-align: center !important;
    padding: 0.25em 0.4em;
    line-height: 1;
}
.header .form-inline .btn {
    border-radius: 0;
    padding: 5px 15px 6px 15px;
    border: 2px solid transparent;
    box-shadow: none;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    background: transparent;
	color: #c4ccdc;
	margin-left: -56px;
	font-size: 15px;
	position:absolute;
	top:5px;
	right:0;
}
.header-1 .form-inline .form-control, .header-1 .form-inline .form-control:hover, .header-1 .form-inline .form-control:focus{
	border: 1px solid $border;
}
.header-2 .form-inline .form-control, .header-2 .form-inline .form-control:hover,  .header-2 .form-inline .form-control:focus{
	border: 1px solid $border;
}
.header-style .form-inline .form-control, .header-style .form-inline .form-control:hover, .header-style .form-inline .form-control:focus{
	border: 1px solid $border;
}
.header .dropdown-menu{
	padding:0;
	border:0;
	box-shadow:$shadow;
	border-radius: 0 0px 4px 4px;
}
.header .bg-image{
	background:linear-gradient(to right bottom, rgba(132, 65, 246,0.7),rgba(212, 40, 234,0.7)),url(../images/photos/tornoto.jpg);
	position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.header .bg-image-2 {
    background: linear-gradient(to right bottom, rgba(251, 196, 52,0.7),rgba(246, 107, 78,0.7)),url(../images/photos/tornoto.jpg);
	position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.header .bg-image-3 {
    background: linear-gradient(to right bottom, rgba(98, 251, 92,0.6),rgba(33, 165, 6,0.6)),url(../images/photos/tornoto.jpg);
	position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.header .bg-image a:hover{
	background:transparent !important;
}
@media (max-width: 480px) {
	.header-brand {
		margin-top: 3px;
	}
	.header .nav-link.leading-none::after {
		top: 12px !important;
	}
	.header .navsearch i {
		font-size: 20px !important;
		margin-top: 3px;
	}
	.header .nav-link.icon {
		font-size: 17px;
	}
	.search-icon{
		margin-top:2px;
	}
	.header .nav-link .badge {
		position: absolute;
		top: -7px;
		right: 6px;
		width: 1rem;
		height: 1rem;
		font-size: 11px;
		text-align: center !important;
		padding: 0.25em 0.4em;
		line-height: 1;
	}
	.header .nav-link {
	     padding: 0.3rem 0.1rem !important;
	     line-height: 1.9;
	     width: 2.5rem;
	     height: 2.5rem;
	     text-align: center;
   	     justify-content: center;
	}
	.app-header .header-brand {
		min-width:auto;
	}
	.app-header1 .header-brand {
		min-width:auto;
	}
	.header-right-icons .dropdown .header-toggler{
		margin-top:9px;
	}
}
@media (max-width: 568px) and (min-width:480px) {
	.form-control.header-search{
		margin-top: -3px !important;
	}
}
@media (max-width: 767px) {
	.header .input-icon.mt-2 {
		margin-top:5px !important;
	}
}
@media (max-width: 768px) {
	.header.header-1  .container, .header.header-2  .container{
		padding-left:0;
		padding-right:0;
	}
	.header.header-1 .navsearch, .header.header-2 .navsearch, .header.header-style .navsearch{
		display:none
	}
	.header.header-1 .full-screen-link, .header.header-2 .full-screen-link, .header.header-style .full-screen-link{
		display:none;
	}
	.header.header-style .header-brand{
		margin-left:1rem !important;
	}
}
@media (max-width: 992px) {
	.header.top-header{
		border-bottom:0;
		padding-top: .25rem;
		padding-bottom: .25rem;
		position: fixed;
		left: 0;
		right: 0;
		top: 0 !important;
		width: 100%;
		z-index: 999;
	}
	.header.app-header{
		border-bottom:0;
		padding-top: .25rem;
		padding-bottom: .25rem;
		position: fixed;
		left: 0;
		right: 0;
		top: 0 !important;
		width: 100%;
		z-index: 9999;
	}
	.sidebar-gone.active .header{
		position:inherit;
	}
	.header .form-inline{
		margin-right: -2px;
		margin-left: auto;
	}
	.header .header-nav .dropdown-menu.show{
		top: 4px !important;
	}
}
.header.header-1 .form-inline .form-control, .header.header-2 .form-inline .form-control, .header.header-style .form-inline .form-control{
	width:300px;
}
.header-1 .navbar .nav-link.nav-link-lg i {
    font-size: 1.1rem;
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    color: #323c53;
    line-height: 32px;
    margin: 0 auto;
    text-align: center;
}
.header-1 .nav-link .badge {
    position: absolute;
    top: -2px;
    right: 6px;
    width: 1rem;
    height: 1rem;
    font-size: 11px;
    text-align: center !important;
    padding: 0.25em 0.4em;
    line-height: 1;
}
.header-1 .badge {
	border-radius:50%;
}
.header-1 .form-inline .btn {
    border-radius: 0;
    padding: 5px 15px 6px 15px;
    border: 2px solid transparent;
    box-shadow: none;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    background: transparent;
    color: #323c53;
    margin-left: -56px;
    font-size: 15px;
}
.header-1 .form-control.header-search {
    color: #323c53;
}
.header-1 .form-inline .form-control::-webkit-input-placeholder{
	color:#8c8ea9 ;
}
.header-1 .animated-arrow span, .animated-arrow span:before, .animated-arrow span:after {
   display:none
}
@media only screen and (max-width: 991px){
	.header-1 .animated-arrow span, .animated-arrow span:before, .animated-arrow span:after {
		cursor: pointer;
		height: 2px;
		width: 17px;
		background: #323c53;
		position: absolute;
		display: block;
		content: '';
	}
	.header-1 .animated-arrow span, .animated-arrow span:before, .animated-arrow span:after {
		cursor: pointer;
		height: 2px;
		width: 17px;
		background: #323c53 ;
		position: absolute;
		display: block;
		content: '';
	}
	.header-1 .hor-toggle.animated-arrow {
		padding: 77px 35px 16px 8px !important;
		margin: 11px 0 0 0px !important;
	}
	
	.app.sidebar-gone.active .header-1 .hor-toggle.animated-arrow {
			padding: 34px 35px 16px 8px !important;
			margin: 11px 0 0 0px !important;
	}
	.app.sidebar-mini.sidebar-gone.sidenav-toggled .header-1 .hor-toggle.animated-arrow {
			padding: 77px 35px 16px 8px !important;
			margin: 11px 0 0 0px !important;
	}
	.header-1  .animated-arrow {
		cursor: pointer;
		padding: 33px 29px 15px 7px;
		margin: 11px 0 0 -11px;
	}
	.header-1 .navbar .nav-link.nav-link-lg i {
		font-size: 1.1rem;
		width: 2.2rem;
		height: 2.2rem;
		border-radius: 50%;
		color: #323c53;
		line-height: 39px;
		margin: 0 auto;
		text-align: center;
		padding: 0px 42px 0px 10px;
	}
	.header-1 .nav-link .badge {
		position: absolute;
		top: -7px;
		width: 1rem;
		height: 1rem;
		font-size: 11px;
		text-align: center !important;
		padding: 0.25em 0.4em;
		line-height: 1;
	}
}
.header-1 .nav-link.icon i {
    color: $color !important;
}
@media only screen and (max-width: 991px){
	
	.header-1 .animated-arrow span, .header-1 .animated-arrow span:before, .header-1 .animated-arrow span:after {
		cursor: pointer;
		height: 2px;
		width: 17px;
		background: #323c53;
		color:#323c53;
		position: absolute;
		display: block;
		content: '';
	}
}
/*--header-2--*/
.header-2 .nav-link .badge {
    position: absolute;
    top: -10px;
    right: 10px;
    width: 1rem;
    height: 1rem;
    font-size: 11px;
    text-align: center !important;
    padding: 0.25em 0.4em;
    line-height: 1;
    border-radius: 50%;
}
.header-2 .nav-link i {
    display: block;
    text-align: center;
    font-size: 20px;
    position: relative;
	color:$color;
}
.header-2 .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.9rem;
    padding-left: 0.9rem;
}
.header-2 .form-inline .btn {
    border-radius: 0;
    padding: 5px 15px 6px 15px;
    border: 2px solid transparent;
    box-shadow: none;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    background: transparent;
    color: #323c53;
    margin-left: -56px;
    font-size: 15px;
}
.header-2 .form-inline .form-control::-webkit-input-placeholder{
	color:#8c8ea9 ;
}
.header-2 .form-control.header-search {
    background: rgba(225,225,225,0.4);
    border: 1px solid $border;
    color: $white;
    padding: 0.375rem 0.75rem;
    margin-top: 4px;
}
.header-style .nav-link i{
	color:$color;
}
@media only screen and (max-width: 1279px) and (min-width: 1025px){
	.horizontal-main2 .horizontalMenu>.horizontalMenu-list>li>a {
		display: block;
		padding: 1rem 0.7rem 1rem;
		text-decoration: none;
		position: relative;
		margin: 0px;
		color: #fcfeff;
	}
}
@media (max-width: 1024px) and (min-width: 992px){
	.horizontal-main2 .horizontalMenu>.horizontalMenu-list>li>a {
		padding: 1rem 0.8rem 1rem !important;
		font-size: 14px;
	}
	
}
@media only screen and (max-width: 991px){
	.header-2 .animated-arrow {
		cursor: pointer;
		padding: 32px 33px 3px 0px;
		margin: 11px 0 0 -11px;
	}
	.header-2 .animated-arrow span, .header-2 .animated-arrow span:before, .header-2 .animated-arrow span:after {
		cursor: pointer;
		height: 2px;
		width: 17px;
		background: #323c53;
		color:#323c53;
		position: absolute;
		display: block;
		content: '';
	}
	
	.header-2 .nav-link .badge {
		position: absolute;
		top: -14px;
		right: 6px;
		width: 1rem;
		height: 1rem;
		font-size: 11px;
		text-align: center !important;
		padding: 0.25em 0.4em;
		line-height: 1;
		border-radius: 50%;
	}
	.header-2 .navbar-nav .dropdown-menu {
		position: absolute;
		float: right;
	}
	.header-2 .dropdown-menu.dropdown-menu-right{
		position: absolute;
		transform: translate3d(-98px, 19px, -16px);
		top: 24px;
		left: 0px;
		will-change: transform;
	}
}
.horizontal-main1 .horizontalMenu>.horizontalMenu-list>li>a.active {
    color: $white;
    background: rgba(225,225,225,0.06);
}
.horizontal-main1.horizontal-main {
    width: 100%;
    position: relative;
    border-top: 0 ;
}
.horizontal-main1 .horizontalMenu>.horizontalMenu-list>li>a, .horizontal-main2 .horizontalMenu>.horizontalMenu-list>li>a{
	color:$white;
}
.horizontal-main2 .horizontalMenu>.horizontalMenu-list>li>a.active {
    color: $white;
    background: rgba(225,225,225,0.06);
}
.horizontal-main2.horizontal-main {
    width: 100%;
    position: relative;
    border-top: 0 ;
}
.horizontal-main1 .horizontalMenu>.horizontalMenu-list>li>a, .horizontal-main2 .horizontalMenu>.horizontalMenu-list>li>a{
	border-left: 1px solid rgba(255,255,255,0.1);
}
.horizontal-main1 .horizontalMenu>.horizontalMenu-list>li:last-child>a,  .horizontal-main2 .horizontalMenu>.horizontalMenu-list>li:last-child>a{
	border-right: 1px solid rgba(255,255,255,0.1);
}
@media only screen and (max-width: 460px){
	.header-2 .navbar-nav .envelope{
		display:none !important;
	}
}
/*--header-3--*/
.header-3 .nav-link .badge {
    position: absolute;
    top: -10px;
    right: 10px;
    width: 1rem;
    height: 1rem;
    font-size: 11px;
    text-align: center !important;
    padding: 0.25em 0.4em;
    line-height: 1;
    border-radius: 50%;
}
.header-3 .nav-link i {
    display: block;
    text-align: center;
    font-size: 20px;
    position: relative;
	color:#323c53;
}
.header-3 .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.9rem;
    padding-left: 0.9rem;
}
.header-3 .form-inline .btn {
    border-radius: 0;
    padding: 5px 15px 6px 15px;
    border: 2px solid transparent;
    box-shadow: none;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    background: transparent;
    color: #323c53;
    margin-left: -56px;
    font-size: 15px;
}
.header-3 .form-inline .form-control::-webkit-input-placeholder{
	color:#8c8ea9;
}
.header-3 .form-control.header-search {
    background: rgba(225,225,225,0.4);
    border: 1px solid rgba(225,225,225,0.1);
    color: $white;
    padding: 0.375rem 0.75rem;
    margin-top: 4px;
}
@media only screen and (max-width: 1279px) and (min-width: 1025px){
	.horizontal-main3 .horizontalMenu>.horizontalMenu-list>li>a {
		display: block;
		padding: 1rem 0.7rem 1rem;
		text-decoration: none;
		position: relative;
		margin: 0px;
		color: #fcfeff;
	}
}
@media (max-width: 1024px) and (min-width: 992px){
	.horizontal-main3 .horizontalMenu>.horizontalMenu-list>li>a {
		padding: 1rem 0.8rem 1rem !important;
		font-size: 14px;
	}
	
}
@media only screen and (max-width: 991px){
	.header-3 .animated-arrow {
		cursor: pointer;
		padding: 32px 33px 3px 0px;
		margin: 11px 0 0 -11px;
	}
	.header-3 .animated-arrow span, .header-3 .animated-arrow span:before, .header-3 .animated-arrow span:after {
		cursor: pointer;
		height: 2px;
		width: 17px;
		background: #323c53;
		color:#323c53;
		position: absolute;
		display: block;
		content: '';
	}
	
	.header-3 .nav-link .badge {
		position: absolute;
		top: -14px;
		right: 6px;
		width: 1rem;
		height: 1rem;
		font-size: 11px;
		text-align: center !important;
		padding: 0.25em 0.4em;
		line-height: 1;
		border-radius: 50%;
	}
	.header-3 .navbar-nav .dropdown-menu {
		position: absolute;
		float: right;
	}
	.header-3 .dropdown-menu.dropdown-menu-right{
		position: absolute;
		transform: translate3d(-98px, 19px, -16px);
		top: 24px;
		left: 0px;
		will-change: transform;
	}
}
.horizontal-main3.horizontal-main {
    width: 100%;
    position: relative;
    background: rgba(52, 1, 108,0.8);
    border-top: 0 ;
}
@media only screen and (max-width: 460px){
	.header-3 .navbar-nav .envelope{
		display:none !important;
	}
}
.header-style .header-brand {
    margin-left: 0 !important;
}
.default-header1 .form-inline .form-control{
	background:$white !important; 
}
.header2 .form-inline .form-control{
	background:$white !important; 
}
.header3 .form-inline .form-control{
	background:$white !important; 
}

/* ###### Header ###### */
/* ###### Select2 ###### */

.select2-container--default {
	.select2-selection--single {
	  background-color: $white;
	  border: 1px solid $border !important;
	}
  
	&.select2-container--focus .select2-selection--multiple {
	  color: #90a1bb;
	  background-color: $white !important;
	  outline: 0;
	  box-shadow: none;
	}
  }
  
  .select2-selection--multiple:placeholder, .select2-container .select2-search--inline .select2-search__field {
	color: #8c8ea9;
  }
  
  .select2-container--default {
	.select2-selection--multiple {
	  background-color: white;
	  border: 1px solid $border !important;
	  cursor: text;
	}
  
	.select2-search--dropdown .select2-search__field {
	  border: 1px solid $border !important;
	}
  
	.select2-selection--multiple {
	  .select2-selection__choice, .select2-selection__choice__remove {
		color: $white !important;
	  }
	}
  
	.select2-results > .select2-results__options {
	  box-shadow: none;
	}
  }
  
  .select2-lg {
	.select2-container .select2-selection--single {
	  height: 2.875rem !important;
	}
  
	.select2-container--default .select2-selection--single .select2-selection__rendered {
	  line-height: 45px !important;
	}
  }
  
  .select2-sm {
	.select2-container .select2-selection--single {
	  height: 1.775rem !important;
	}
  
	.select2-container--default .select2-selection--single {
	  .select2-selection__rendered {
		line-height: 27px !important;
	  }
  
	  .select2-selection__arrow b {
		top: 50% !important;
		left: 70%;
	  }
	}
  }
  
  /* ###### Select2 ###### */
/* ###### Badges ###### */

.badge {
	display: inline-block;
	padding: 0.35em 0.75em;
	font-weight: 500;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 4px;
	font-size: 11px;
  }
  
  .badge-pill {
	padding-right: .6em;
	padding-left: .6em;
	border-radius: 10rem;
  }
  
  .badgetext {
	float: right;
  }
  
  .btn .badge {
	position: relative;
	top: -1px;
  }
  
  .badge-default {
	color: $color;
	background: rgba(236, 238, 249, 0.9);
  
	&[href] {
	  &:hover, &:focus {
		color: $color;
		text-decoration: none;
		background-color: rgba(236, 238, 249, 0.9);
	  }
	}
  }
  
  .badge-success {
	color: $white;
	background: #33cc33 !important;
  
	&[href] {
	  &:hover, &:focus {
		color: $white;
		text-decoration: none;
		background-color: #448700;
	  }
	}
  }
  
  .badge-gradient-success {
	color: $white;
	background: linear-gradient(to bottom right, #62fb62, #21a544) !important;
  
	&[href] {
	  &:hover, &:focus {
		color: $white;
		text-decoration: none;
		background: linear-gradient(to bottom right, #5cf55c, #1d983e) !important;
	  }
	}
  }
  
  .badge-gradient-default {
	background: linear-gradient(to bottom right, #efeeee 0%, #d1cfcf 100%) !important;
	color: #90a1bb;
  }
  
  .badge-info {
	color: $white;
	background: $info  !important;
  
	&[href] {
	  &:hover, &:focus {
		color: $white;
		text-decoration: none;
		background-color: #1db7ec;
	  }
	}
  }
  
  .badge-warning {
	color: $white;
	background: $warning !important;
  
	&[href] {
	  &:hover, &:focus {
		color: $white;
		text-decoration: none;
		background-color: #fab525;
	  }
	}
  }
  
  .badge-danger {
	color: $white;
	background: #ff1a1a !important;
  
	&[href] {
	  &:hover, &:focus {
		color: $white;
		text-decoration: none;
		background-color: #f42341;
	  }
	}
  }
  
  .badge-light {
	color: #455b7e;
	background-color: #e7e9f5;
  
	&[href] {
	  &:hover, &:focus {
		color: #455b7e;
		text-decoration: none;
		background-color: #dae0e5;
	  }
	}
  }
  
  .badge-dark {
	color: $white;
	background-color: $dark;
  
	&[href] {
	  &:hover, &:focus {
		color: $white;
		text-decoration: none;
		background-color: #1d2124;
	  }
	}
  }
  
  .badge-white {
	color: #455b7e;
	background-color: #e7e9f5;
  }
  
  .btn .badge {
	position: relative;
	top: -1px;
  }
  
  .badge-success-gradient {
	color: $white;
	background: linear-gradient(to bottom right, #21a544 0%, #62fb62 100%) !important;
  
	&[href] {
	  &:hover, &:focus {
		color: $white;
		text-decoration: none;
		background-color: #448700;
	  }
  
	  &:hover, &:focus {
		color: $white;
		text-decoration: none;
		background: linear-gradient(to bottom right, #5cf55c, #1d983e) !important;
	  }
	}
  
	color: $white;
	background: linear-gradient(to bottom right, #62fb62, #21a544) !important;
  }
  
  .badge-info-gradient {
	color: $white;
	background: linear-gradient(to bottom right, #1e63c3 0%, #00f2fe 100%) !important;
  
	&[href] {
	  &:hover, &:focus {
		color: $white;
		text-decoration: none;
		background-color: #1594ef;
	  }
  
	  &:hover, &:focus {
		color: $white;
		text-decoration: none;
		background: linear-gradient(to bottom right, #05ebf6 0%, #205fb6 100%) !important;
	  }
	}
  
	color: $white;
	background: linear-gradient(to bottom right, #00f2fe 0%, #1e63c3 100%) !important;
  }
  
  .badge-warning-gradient {
	color: $white;
	background: linear-gradient(to bottom right, #f66b4e 0%, #fbc434 100%) !important;
  
	&[href] {
	  &:hover, &:focus {
		color: $white;
		text-decoration: none;
		background-color: #c29d0b;
	  }
  
	  &:hover, &:focus {
		color: $white;
		text-decoration: none;
		background: linear-gradient(to bottom right, #ecb831 0%, #eb6448 100%) !important;
	  }
	}
  
	color: $white;
	background: linear-gradient(to bottom right, #f66b4e 0%, #fbc434 100%) !important;
  }
  
  .badge-danger-gradient {
	color: $white;
	background-image: linear-gradient(to bottom right, #ff1a1a 0%, #FF4B2B 100%) !important;
  
	&[href] {
	  &:hover, &:focus {
		color: $white;
		text-decoration: none;
		background-color: #a11918;
	  }
  
	  &:hover, &:focus {
		color: $white;
		text-decoration: none;
		background-color: #990000;
		background-image: linear-gradient(to bottom right, #8f0101 0%, #f90404 100%);
	  }
	}
  
	color: $white;
	background-color: #990000;
	background-image: linear-gradient(to bottom right, #ff0000 0%, #990000 100%);
  }
  
  .badge-light-gradient {
	color: #455b7e;
	background-color: #e7e9f5;
  
	&[href] {
	  &:hover, &:focus {
		color: #455b7e;
		text-decoration: none;
		background-color: #dae0e5;
	  }
	}
  }
  
  .badge-dark {
	color: $white;
	background-color: $dark;
  
	&[href] {
	  &:hover, &:focus {
		color: $white;
		text-decoration: none;
		background-color: #1d2124;
	  }
	}
  }
  
  /* ###### Badges ###### */
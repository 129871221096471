/* ###### Navigation ###### */

.nav {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
  }
  
  .nav-link {
	display: block;
	padding: 0.2rem 0.9rem;
  
	&:hover, &:focus {
	  text-decoration: none;
	}
  
	&.disabled {
	  color: #9ea7af;
	}
  
	&.disable {
	  color: #cba4f7;
	}
  }
  
  .nav-tabs {
	border-bottom: 1px solid $border;
  
	.nav-item {
	  margin-bottom: -1px;
	}
  
	.nav-link {
	  border: 1px solid transparent;
  
	  &:hover, &:focus {
		border: 0;
	  }
  
	  &.disabled {
		color: $gray;
		background-color: transparent;
		border-color: transparent;
	  }
  
	  &.active {
		border: 0;
	  }
	}
  
	.nav-item.show .nav-link {
	  border: 0;
	}
  
	.dropdown-menu {
	  border-top-left-radius: 0;
	  border-top-right-radius: 0;
	}
  }
  
  .nav-pills {
	.nav-link.active, .show > .nav-link {
	  color: $white;
	}
  }
  
  .nav-fill .nav-item {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	text-align: center;
  }
  
  .nav-justified .nav-item {
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-ms-flex-positive: 1;
	flex-grow: 1;
	text-align: center;
  }
  
  .tab-content > {
	.tab-pane {
	  display: none;
	}
  
	.active {
	  display: block;
	}
  }
  
  .navbar {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 0.5rem 1rem;
  
	> {
	  .container, .container-fluid {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-pack: justify;
		justify-content: space-between;
	  }
	}
  }
  
  .navbar-brand {
	display: inline-block;
	padding-top: 0.359375rem;
	padding-bottom: 0.359375rem;
	margin-right: 1rem;
	font-size: 1.125rem;
	line-height: inherit;
	white-space: nowrap;
  
	&:hover, &:focus {
	  text-decoration: none;
	}
  }
  
  .navbar-nav {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
  
	.nav-link {
	  padding-right: 0;
	  padding-left: 0;
	}
  
	.dropdown-menu {
	  position: static;
	  float: none;
	}
  }
  
  .navbar-text {
	display: inline-block;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
  }
  
  .navbar-collapse {
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-ms-flex-align: center;
	align-items: center;
  }
  
  .navbar-toggler {
	padding: 0.25rem 0.75rem;
	font-size: 1.125rem;
	line-height: 1;
	background-color: transparent;
	border: 1px solid transparent;
  
	&:hover, &:focus {
	  text-decoration: none;
	}
  
	&:not(:disabled):not(.disabled) {
	  cursor: pointer;
	}
  }
  
  .navbar-toggler-icon {
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
	vertical-align: middle;
	content: "";
	background: no-repeat center center;
	background-size: 100% 100%;
  }
  
  @media (max-width: 575.98px) {
	.navbar-expand-sm > {
	  .container, .container-fluid {
		padding-right: 0;
		padding-left: 0;
	  }
	}
  }
  
  @media (min-width: 576px) {
	.navbar-expand-sm {
	  -ms-flex-flow: row nowrap;
	  flex-flow: row nowrap;
	  -ms-flex-pack: start;
	  justify-content: flex-start;
  
	  .navbar-nav {
		-ms-flex-direction: row;
		flex-direction: row;
  
		.dropdown-menu {
		  position: absolute;
		}
  
		.nav-link {
		  padding-right: 0.5rem;
		  padding-left: 0.5rem;
		}
	  }
  
	  > {
		.container, .container-fluid {
		  -ms-flex-wrap: nowrap;
		  flex-wrap: nowrap;
		}
	  }
  
	  .navbar-collapse {
		display: -ms-flexbox !important;
		display: flex !important;
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
	  }
  
	  .navbar-toggler {
		display: none;
	  }
	}
  }
  
  @media (max-width: 767.98px) {
	.navbar-expand-md > {
	  .container, .container-fluid {
		padding-right: 0;
		padding-left: 0;
	  }
	}
  }
  
  @media (min-width: 768px) {
	.navbar-expand-md {
	  -ms-flex-flow: row nowrap;
	  flex-flow: row nowrap;
	  -ms-flex-pack: start;
	  justify-content: flex-start;
  
	  .navbar-nav {
		-ms-flex-direction: row;
		flex-direction: row;
  
		.dropdown-menu {
		  position: absolute;
		}
  
		.nav-link {
		  padding-right: 0.5rem;
		  padding-left: 0.5rem;
		}
	  }
  
	  > {
		.container, .container-fluid {
		  -ms-flex-wrap: nowrap;
		  flex-wrap: nowrap;
		}
	  }
  
	  .navbar-collapse {
		display: -ms-flexbox !important;
		display: flex !important;
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
	  }
  
	  .navbar-toggler {
		display: none;
	  }
	}
  }
  
  @media (max-width: 991.98px) {
	.navbar-expand-lg > {
	  .container, .container-fluid {
		padding-right: 0;
		padding-left: 0;
	  }
	}
  }
  
  @media (min-width: 992px) {
	.navbar-expand-lg {
	  -ms-flex-flow: row nowrap;
	  flex-flow: row nowrap;
	  -ms-flex-pack: start;
	  justify-content: flex-start;
  
	  .navbar-nav {
		-ms-flex-direction: row;
		flex-direction: row;
  
		.dropdown-menu {
		  position: absolute;
		}
  
		.nav-link {
		  padding-right: 0.5rem;
		  padding-left: 0.5rem;
		}
	  }
  
	  > {
		.container, .container-fluid {
		  -ms-flex-wrap: nowrap;
		  flex-wrap: nowrap;
		}
	  }
  
	  .navbar-collapse {
		display: -ms-flexbox !important;
		display: flex !important;
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
	  }
  
	  .navbar-toggler {
		display: none;
	  }
	}
  }
  
  @media (max-width: 1279.98px) {
	.navbar-expand-xl > {
	  .container, .container-fluid {
		padding-right: 0;
		padding-left: 0;
	  }
	}
  }
  
  @media (min-width: 1280px) {
	.navbar-expand-xl {
	  -ms-flex-flow: row nowrap;
	  flex-flow: row nowrap;
	  -ms-flex-pack: start;
	  justify-content: flex-start;
  
	  .navbar-nav {
		-ms-flex-direction: row;
		flex-direction: row;
  
		.dropdown-menu {
		  position: absolute;
		}
  
		.nav-link {
		  padding-right: 0.5rem;
		  padding-left: 0.5rem;
		}
	  }
  
	  > {
		.container, .container-fluid {
		  -ms-flex-wrap: nowrap;
		  flex-wrap: nowrap;
		}
	  }
  
	  .navbar-collapse {
		display: -ms-flexbox !important;
		display: flex !important;
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
	  }
  
	  .navbar-toggler {
		display: none;
	  }
	}
  }
  
  .navbar-expand {
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-ms-flex-pack: start;
	justify-content: flex-start;
  
	> {
	  .container, .container-fluid {
		padding-right: 0;
		padding-left: 0;
	  }
	}
  
	.navbar-nav {
	  -ms-flex-direction: row;
	  flex-direction: row;
  
	  .dropdown-menu {
		position: absolute;
	  }
  
	  .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	  }
	}
  
	> {
	  .container, .container-fluid {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	  }
	}
  
	.navbar-collapse {
	  display: -ms-flexbox !important;
	  display: flex !important;
	  -ms-flex-preferred-size: auto;
	  flex-basis: auto;
	}
  
	.navbar-toggler {
	  display: none;
	}
  }
  
  .navbar-light {
	.navbar-brand {
	  color: rgba(0, 0, 0, 0.9);
  
	  &:hover, &:focus {
		color: rgba(0, 0, 0, 0.9);
	  }
	}
  
	.navbar-nav {
	  .nav-link {
		color: rgba(0, 0, 0, 0.5);
  
		&:hover, &:focus {
		  color: rgba(0, 0, 0, 0.7);
		}
  
		&.disabled {
		  color: rgba(0, 0, 0, 0.3);
		}
	  }
  
	  .show > .nav-link, .active > .nav-link {
		color: rgba(0, 0, 0, 0.9);
	  }
  
	  .nav-link {
		&.show, &.active {
		  color: rgba(0, 0, 0, 0.9);
		}
	  }
	}
  
	.navbar-toggler {
	  color: rgba(0, 0, 0, 0.5);
	  border-color: rgba(0, 0, 0, 0.1);
	}
  
	.navbar-toggler-icon {
	  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
	}
  
	.navbar-text {
	  color: rgba(0, 0, 0, 0.5);
  
	  a {
		color: rgba(0, 0, 0, 0.9);
  
		&:hover, &:focus {
		  color: rgba(0, 0, 0, 0.9);
		}
	  }
	}
  }
  
  .navbar-dark {
	.navbar-brand {
	  color: $white;
  
	  &:hover, &:focus {
		color: $white;
	  }
	}
  
	.navbar-nav {
	  .nav-link {
		color: rgba(255, 255, 255, 0.5);
  
		&:hover, &:focus {
		  color: rgba(255, 255, 255, 0.75);
		}
  
		&.disabled {
		  color: rgba(255, 255, 255, 0.25);
		}
	  }
  
	  .show > .nav-link, .active > .nav-link {
		color: $white;
	  }
  
	  .nav-link {
		&.show, &.active {
		  color: $white;
		}
	  }
	}
  
	.navbar-toggler {
	  color: rgba(255, 255, 255, 0.5);
	  border-color: rgba(255, 255, 255, 0.1);
	}
  
	.navbar-toggler-icon {
	  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
	}
  
	.navbar-text {
	  color: rgba(255, 255, 255, 0.5);
  
	  a {
		color: $white;
  
		&:hover, &:focus {
		  color: $white;
		}
	  }
	}
  }
  
  /* ###### Navigation ###### */